import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../../layouts/default"

import AudioPlayer from "../../../components/audio-player"
import Breadcrumb from "../../../components/breadcrumb"
import Column from "../../../components/column"
import Columns from "../../../components/columns"
import Paragraph from "../../../components/paragraph"
import Image from "../../../components/image"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"
import Text from "../../../components/text"

const LeichteSpracheMachen = () => {
  const data = useStaticQuery(graphql`
    query LeichteSpracheMachenIndexQuery {
      gedichte: file(relativePath: { eq: "leichte-sprache/gedichte.jpg" }) {
        ...smallImage
      }
      briefe: file(relativePath: { eq: "leichte-sprache/briefe.jpg" }) {
        ...smallImage
      }
      tisch: file(relativePath: { eq: "leichte-sprache/tisch.jpg" }) {
        ...smallImage
      }
      gang: file(relativePath: { eq: "leichte-sprache/gang.jpg" }) {
        ...smallImage
      }
      hoeren: file(relativePath: { eq: "leichte-sprache/hoeren.jpg" }) {
        ...smallImage
      }
      fuehlen: file(relativePath: { eq: "leichte-sprache/fuehlen.jpg" }) {
        ...smallImage
      }
      sprachlabor1: file(
        relativePath: { eq: "leichte-sprache/sprachlabor-1.jpg" }
      ) {
        ...smallImage
      }
      sprachlabor2: file(
        relativePath: { eq: "leichte-sprache/sprachlabor-2.jpg" }
      ) {
        ...smallImage
      }
      garten: file(relativePath: { eq: "leichte-sprache/garten.jpg" }) {
        ...smallImage
      }
      laufstrecke: file(
        relativePath: { eq: "leichte-sprache/laufstrecke.jpg" }
      ) {
        ...smallImage
      }
    }
  `)

  return (
    <Layout backdrop="aussen">
      <Seo
        title="Das kann man im Hölderlin∙turm machen"
        description="Im Museum können Sie zum Beispiel Gedichte lesen und an∙hören. Die Gedichte hat Hölderlin geschrieben."
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Leichte Sprache",
                link: "/leichte-sprache",
              },
              {
                title: "Das kann man im Hölderlin∙turm machen",
                link: "/leichte-sprache/machen",
              },
            ]}
          />
          <PageTitle>Das kann man im Hölderlin∙turm machen</PageTitle>
          <Paragraph>
            Das Museum hat 7 Räume.
            <br />
            Ein paar Räume sind im Erd∙geschoss.
            <br />
            Ein paar Räume sind im ersten Stock.
          </Paragraph>
        </Stack>

        <Stack space={[12, 24]}>
          <Columns collapse={[true, false]} space={12}>
            <Column width={["full", "1/3"]}>
              <Image
                image={data.gedichte.childImageSharp.gatsbyImageData}
                attribution="David Franck"
                alt="Ein Raum in der Ausstellung. An der Wand kann man Hölderlins Gedicht ›Aussicht‹ lesen. Daneben hängt in einem Rahmen. Darin kann man das Papier sehen, auf das Hölderlin das Gedicht geschrieben hat."
              />
            </Column>
            <Column>
              <Paragraph>
                Im Museum können Sie zum Beispiel Gedichte lesen und an∙hören.{" "}
                <br />
                Die Gedichte hat Hölderlin geschrieben. <br />
                Sie können das Papier sehen, <br />
                auf dem Hölderlin die Gedichte auf∙geschrieben hat. <br />
                Und Sie können sehen: <br />
                So hat Hölderlins Hand∙schrift aus∙gesehen.
              </Paragraph>
            </Column>
          </Columns>

          <Columns collapse={[true, false]} space={12}>
            <Column width={["full", "1/3"]}>
              <Image
                image={data.briefe.childImageSharp.gatsbyImageData}
                attribution="David Franck"
                alt="Unter einer Glashaube liegt ein alter, handgeschriebener Brief. Rechts daneben sind ein Holzbrett und ein Lautsprecher zu sehen."
              />
            </Column>
            <Column>
              <Paragraph>
                Und Sie können Briefe an∙schauen. <br />
                Zum Beispiel Briefe von Familie Zimmer an die Familie von
                Hölderlin. <br />
                In den Briefen steht, <br />
                wie es Hölderlin geht. <br />
                Und wie er seine Zeit verbringt.
              </Paragraph>
            </Column>
          </Columns>

          <Columns collapse={[true, false]} space={12}>
            <Column width={["full", "1/3"]}>
              <Image
                image={data.tisch.childImageSharp.gatsbyImageData}
                attribution="David Franck"
                alt="Ein Zimmer mit einem kleinen Tisch in der Mitte. Der Tisch ist aus dunklem Nussbaum-Holz. Die Tischbeine sind gedrechselt: Sie haben eine besondere Form."
              />
            </Column>
            <Column>
              <Paragraph>
                Im Museum erfahren Sie: <br />
                So hat Hölderlin an seinen Gedichten gearbeitet. <br />
                An diesem Tisch hat Hölderlin viele Gedichte geschrieben.
              </Paragraph>
            </Column>
          </Columns>

          <Columns collapse={[true, false]} space={12}>
            <Column width={["full", "1/3"]}>
              <Image
                image={data.gang.childImageSharp.gatsbyImageData}
                alt="Ein langer Gang mit weißen Wänden und einem Natursein-Boden. An der Decke hängen Lichtschienen. Ein großes schwarzes Pendel schwingt an der Wand hin und her."
                attribution="David Franck"
              />
            </Column>
            <Column>
              <Paragraph>
                Durch diesen Gang ist Hölderlin gelaufen. <br />
                Hin und her. <br />
                Manchmal ist er stunden∙lang durch den Gang gelaufen. <br />
                Dabei hat er seine Gedichte auf∙gesagt.
              </Paragraph>
            </Column>
          </Columns>

          <Columns collapse={[true, false]} space={12}>
            <Column width={["full", "1/3"]}>
              <Image
                image={data.hoeren.childImageSharp.gatsbyImageData}
                attribution="David Franck"
                alt="Eine Hand hält einen Lautsprecher."
              />
            </Column>
            <Column>
              <Stack>
                <Paragraph>
                  In jedem Raum gibt es ein anderes Gedicht von Hölderlin.{" "}
                  <br />
                  Diese Gedichte können Sie nicht nur lesen. <br />
                  Sie können die Gedichte auch hören: <br />
                  So klingen die Gedichte.
                </Paragraph>
                <AudioPlayer
                  src={`dauerausstellung/01-die-linien-des-lebens/linien-des-lebens`}
                  title={
                    <>
                      Christian Reiner liest{" "}
                      <Text bold as="span">
                        ›Die Linien des Lebens‹
                      </Text>
                    </>
                  }
                />
              </Stack>
            </Column>
          </Columns>

          <Columns collapse={[true, false]} space={12}>
            <Column width={["full", "1/3"]}>
              <Image
                image={data.fuehlen.childImageSharp.gatsbyImageData}
                attribution="David Franck"
                alt="Ein Besucher steht vor einer Station im Museum. Er hält einen Lautsprecher an sein Ohr. Seine andere Hand liegt auf einem Holzbrett."
              />
            </Column>
            <Column>
              <Stack space={6}>
                <Paragraph>
                  Und Sie können die Gedichte fühlen.
                  <br />
                  Dafür gibt es in jedem Raum ein Holz∙brett.
                  <br />
                  Darauf können Sie Ihre Hand legen.
                  <br />
                  Das Brett vibriert dann.
                  <br />
                  Vibrieren bedeutet:
                  <br />
                  Das Brett wackelt und summt.
                </Paragraph>
                <Paragraph>
                  Das Brett vibriert passend zur Betonung von dem Gedicht.
                  <br />
                  Sie können spüren:
                  <br />
                  So wird das Gedicht betont.
                </Paragraph>
              </Stack>
            </Column>
          </Columns>

          <Columns collapse={[true, false]} space={12}>
            <Column width={["full", "1/3"]}>
              <Image
                image={data.sprachlabor1.childImageSharp.gatsbyImageData}
                attribution="David Franck"
                alt="Auf einem farbigen Bildschirm ist ein Spiel mit einer springenden Figur zu sehen. Eine junge Besucherin sitzt vor dem Bildschirm. Sie hat einen Kopfhörer auf."
              />
            </Column>
            <Column>
              <Paragraph>
                Im Museum gibt es auch ein Sprach∙labor.
                <br />
                Dort gibt es 5 Bild∙schirme.
                <br />
                An den Bild∙schirmen können Sie zum Beispiel ein Spiel <br />
                zu den Gedichten von Hölderlin spielen.
                <br />
                Im Spiel können Sie Gedichte von Hölderlin hören.
                <br />
                Sie können eine Spiel∙figur durch das Gedicht bewegen.
              </Paragraph>
            </Column>
          </Columns>

          <Columns collapse={[true, false]} space={12}>
            <Column width={["full", "1/3"]}>
              <Image
                image={data.sprachlabor2.childImageSharp.gatsbyImageData}
                alt="Eine weiße Tafel mit grünen Magneten. Auf den Magneten stehen Wörter. Ein Besucher ordnet die Wörter zu einem Gedicht an."
                attribution="David Franck"
              />
            </Column>
            <Column>
              <Paragraph>
                Im Sprach∙labor können Sie auch mit den Gedichten von Hölderlin
                spielen. <br />
                Sie können die Wörter zu einem neuen Gedicht zusammen∙setzen.
              </Paragraph>
            </Column>
          </Columns>

          <Columns collapse={[true, false]} space={12}>
            <Column width={["full", "1/3"]}>
              <Image
                image={data.garten.childImageSharp.gatsbyImageData}
                attribution="Gudrun de Maddalena"
                alt="Eine große Stein-Treppe führt in den Museumsgarten."
              />
            </Column>
            <Column>
              <Paragraph>
                Zum Museum gehört auch ein Garten. <br />
                Dort ist auch noch ein Teil der Aus∙stellung. <br />
                Als Hölderlin im Turm gewohnt hat, <br />
                hat der Garten zum Kranken∙haus gehört. <br />
                Jetzt gehört der Garten zum Museum.
              </Paragraph>
            </Column>
          </Columns>
          <Columns collapse={[true, false]} space={12}>
            <Column width={["full", "1/3"]}>
              <Image
                image={data.laufstrecke.childImageSharp.gatsbyImageData}
                alt="Ein geschwungener Weg im Museumsgarten."
              />
            </Column>
            <Column>
              <Stack space={6}>
                <Paragraph>
                  Im Garten gibt es einen Weg. <br />
                  Auf diesem Weg können Sie ein Gedicht an∙hören. <br />
                  Mit dem Media∙Guide können Sie auswählen: <br />
                  Das Gedicht wird entweder langsam vor∙gelesen. <br />
                  Oder in normaler Geschwindigkeit. <br />
                  Oder es wird sehr schnell vor∙gelesen. <br />
                </Paragraph>
                <Paragraph>
                  Dann können Sie mit dem Gedicht auf dem Weg spazieren gehen.{" "}
                  <br />
                  Wenn das Gedicht langsam vorgelesen wird, haben Sie mehr Zeit
                  bis zum Ende der Strecke. <br />
                  Wenn das Gedicht schnell vorgelesen wird, müssen Sie sich
                  beeilen, <br />
                  damit Sie am Ende der Strecke ankommen, wenn auch das Gedicht
                  zu Ende ist.
                </Paragraph>
              </Stack>
            </Column>
          </Columns>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default LeichteSpracheMachen
